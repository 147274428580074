.react-calendar {
  border: none !important;
  padding:20px 24px 20px 24px;
  margin: auto;
}

/* Remove border from each date tile */
.react-calendar__tile {
  border: none !important;
  color: #64748B !important;
  font-family: 'Montserrat', sans-serif !important;
  
}

/* Remove borders from navigation buttons */
.react-calendar__navigation {
  border: none !important;
  color: #001C55 !important;
  font-weight: 700;
  font-size: 16px;
}
.react-calendar__navigation button {
  color: #001C55 !important; /* Set color for navigation buttons (prev, next) */
  font-size: 16px;
  font-weight: 700;
  font-family:'Montserrat', sans-serif !important ;
}
.react-calendar__navigation .react-calendar__navigation__prev2-button,
.react-calendar__navigation .react-calendar__navigation__next2-button
{
  display:none !important;
}
.react-calendar__navigation .react-calendar__navigation__prev-button{
  color:'red' !important;
}
.react-calendar__month-view__weekdays {
  color: #64748B !important; /* Set the desired color */
  font-weight: normal !important;
  font-family: 'Montserrat', sans-serif !important;
  text-decoration: none !important; 
}

/* Optional: Change the color when the day name is active (hovered or selected) */
.react-calendar__month-view__weekdays__weekday {
  color: #64748B !important;
  font-weight: 400px !important;
  font-family: 'Montserrat', sans-serif !important;
  text-decoration: none !important; 
  text-transform: lowercase !important;
}
.react-calendar__month-view__weekdays__weekday:first-letter {
  text-transform: uppercase !important; /* Capitalize the first letter */
}
.react-calendar__tile--active {
  background-color: #5274B5 !important; /* Set the background color */
  color: white !important; /* Set text color */
  font-weight: 600 !important;
  border-radius: 50% !important; /* Make it circular */
  padding: 0.5rem !important; /* Adjust padding */
  
}
/* Big calendar css */
.rbc-header {
  font-family: 'Montserrat', sans-serif !important; /* Change font family */
  color: #94A3B8; /* Change text color */
  font-weight: normal !important; /* Remove bold styling */
  font-size: 12px !important; /* Adjust font size if needed */
  padding :15px 3px !important;
  text-transform: uppercase !important;
}
.rbc-month-view {
  border: none !important; /* Removes the border */
  box-shadow: none !important; /* Removes any shadow if present */
  width: 100% !important;
  padding: 0;
  margin: 0;
}
.rbc-day-slot .rbc-event-label{
  display:none !important
}


.rbc-date-cell {
color: #64748B ; 
font-family: 'Montserrat', sans-serif !important; 
display: flex !important;
align-items: center !important;
justify-content: center !important;
text-align: center !important;
font-weight: 400 !important;
font-size: 10px !important;
}


.rbc-date-cell .rbc-current {
  color: #64748B ; 
}

.rbc-event {
  top: 5px !important;         
  left: 5px !important;          
  z-index: 10 !important;
  border-radius: 6px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important; 
  display: flex !important;
  align-items: center !important;
  padding: 2px 5px !important;
  font-size: 12px !important; 
  width:100% !important;
}

.rbc-day-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative; 
}

.rbc-off-range-bg {
  color: #94A3B8 !important; 
  background-color: transparent !important; 
  font-family: 'Montserrat', sans-serif !important;
  font-weight: normal !important; 
}
.rbc-day-bg {
display: flex;
align-items: center; 
justify-content: center;
text-align: center; 
font-family: 'Montserrat', sans-serif; 
font-weight: normal; 
color: #64748B; 
border-right: 1px solid #E2E8F0 !important;
}
.rbc-button-link {
color:'#64748B' !important;
font-size: 10px !important;
margin-top:5px !important;

}
.rbc-current .rbc-button-link {
font-size: 10px !important;
margin-top:5px !important;
background-color: #5274B5 !important; 
color: white !important; 
border-radius: 50% !important;
font-weight: bold; 
padding: 4px;
margin-bottom: 5px !important;
}

.rbc-today{
  background-color: white !important;
}

/* Ensure numbers are properly centered in the cells */

/* Custom CSS for the dropdown */
.custom-dropdown {
background-color: #E0F2F8 !important; /* Light background color */
border-radius: 26px !important; /* Rounded borders */
border: none !important;
font-family: 'Montserrat', sans-serif !important; /* Custom font */
font-size: 14px !important; /* Adjust font size */
}
.custom-dropdown .MuiOutlinedInput-notchedOutline {
border: none !important; /* Removes the border */

}

/* Remove the focus outline (blue border on focus) */
.custom-dropdown .Mui-focused .MuiOutlinedInput-notchedOutline {
border: none !important;
outline: none !important;
}

/* Optional: Add subtle styling if needed */
.custom-dropdown .MuiOutlinedInput-root {
background-color: transparent; /* Make background transparent */
box-shadow: none; /* Remove any shadow */

}
.custom-dropdown .MuiInputBase-input{
padding: 6px 30px 6px 12px !important;
}
.MuiMenu-paper{
border-radius: 20px !important;

}


/* Custom MenuItem (dropdown items) styling */
.custom-menu-item {
font-family: 'Montserrat', sans-serif; 
font-size: 14px; 
color: #475569; 
padding: 10px 15px; 
border: 1px solid white !important;
}


.custom-menu-item:hover {
background-color: #E0F2F8; 
color: #475569; 
}
.custom-label{
font-size: 20px !important ;
 font-weight: 700 !important;
 font-family:'Montserrat', sans-serif !important;
 color:#001C55  !important
}
.rbc-day-bg, .rbc-date-cell {
border-color: #E2E8F0 !important; /* Change border color */
}

/* Remove extra borders around the calendar grid */



.rbc-row {
border-bottom: none !important; /* Remove any additional border from the active row */
}
/* Remove the last vertical and horizontal borders */
.rbc-day-bg:last-child {
border-right: none !important;
}

/* Remove the last horizontal border (on the bottom of the last row) */
.rbc-row:last-child {
border-bottom: none !important;
}

.rbc-header + .rbc-header{
border-left: none !important;
}
.rbc-calendar{
width:100% !important;
}
.rbc-time-header-content{
border: none !important
}
.rbc-time-view{
border:none !important
}
.rbc-time-header.rbc-overflowing{
border:none !important
}
.rbc-time-content > * + * > *{
border:none !important;

}
.rbc-timeslot-group{
border-bottom: none !important
}
.rbc-time-content{
border-top:none !important
}
.rbc-time-view .rbc-time-gutter{
color:#94A3B8 !important;
font-size: 12px !important;
font-weight: 400 !important;
font-family:'Montserrat', sans-serif !important;
}
.rbc-day-slot .rbc-time-slot{
border-bottom:1px solid #E2E8F0;
left:15px;
}

.rbc-time-content .rbc-day-slot .rbc-time-slot{
  left:unset !important;
  }

.rbc-time-view .rbc-row:last-child, .rbc-time-view .rbc-header{
border-bottom: none !important;
}
.rbc-allday-cell{
display: none !important;
}
.rbc-time-content > .rbc-day-slot{
  border-right:1px solid #E2E8F0 ;
}
.rbc-time-content > .rbc-day-slot:last-child{
  border-right:none ;
}
/* Ensure only single lines are shown for each time slot in the Day view */
.rbc-time-slot {
display: flex;
justify-content: center;
align-items: center;
position: relative;
height: 40px;  /* Adjust height as needed */
border-bottom: none !important; /* Remove bottom border */
}


/* Hide any extra event lines by restricting event placement */




/* Remove the extra lines for other events in the day view */
.rbc-day-slot .rbc-event {
position: unset !important;
left: 0;
right: 0;
top: 0;
width: 100%;
height: 100%;
z-index: 2; /* Ensure it's on top of the time slot */
margin: 0;
box-sizing: border-box;
border-radius: 8px;
background-color: #0074BF; /* Default background color for the first event */
color: white;
display: block;
overflow: hidden;
}

.rbc-event-content {
font-size: 12px;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
}

.rbc-day-slot .rbc-events-container .rbc-event{
  height: auto !important;
}

.rbc-day-slot .rbc-event-content{
  padding-block: 7px;
}

.rbc-day-slot .rbc-events-container{
  margin-right: 3px !important;
}



.rbc-day-slot {
border-bottom: none !important; /* Remove bottom border from each day slot */
position: relative;
}
.rbc-day-slot .rbc-event:nth-child(n+2) {
/* display: block;  */

color: white; 
z-index: 1; 
/* height: 30px;  */
margin-top: 5px
}
.rbc-day-slot .rbc-time-slot:first-child{
border-top: none !important;
}
.rbc-day-slot .rbc-time-slot:nth-child(2){
position: relative;
bottom: 7px; 
border-top: 1px solid #E2E8F0;
}
.react-calendar__month-view__weekdays__weekday abbr{
text-decoration-line: none !important;
}
.rbc-current-time-indicator{
background-color: #5274B5 !important;
left:10px !important

}
.rbc-current-time-indicator::before {
content: "";
position: absolute;
top: 50%;
left: -5px; 
transform: translate(-50%, -50%);
width: 8px;
height: 8px; 
background-color: #0074BF; 
border-radius: 50%; 
z-index: 15; 
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th{
  display: none !important;
}
.rbc-agenda-view table.rbc-agenda-table{
  border: none !important;
}
.rbc-agenda-content{
  border:none !important
}





.agenda-date {
  margin-bottom: 5px;
  color: #94A3B8;
  font-size: 10px;
  font-family: 'Montserrat', sans-serif !important;
}

.agenda-event-box {
  border: 1px solid #004E64;
  padding: 8px;
  border-radius: 6px;
  background-color: white;
  color: #004E64;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  font-size: 11px !important;
  font-weight: 400 !important;
  margin-left: 0;
} 
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td{
  border-left: none !important;
}
.agenda-time {
  font-size: 12px;
  color: #666;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell{
  display: none !important;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr{
  border-top: none !important;
}
.rbc-show-more {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.agenda-event-box.not-first-event {
  margin-left: 52px; /* Space before all events after the first one on the same date */
}










.payment-form {
  max-width: 500px;
  margin: auto;
  width:100%
}

.input-row {
  display: flex;
  gap: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.half-width {
  width: 50%;
  padding:10px
}

.input-field {
  border: 1px solid #CBD5E1;
  padding: 10px;
  border-radius: 15px;
  background: white;
}

.pay-button {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background: #0074BF;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 20px;

  font-weight: 800;
  height: 40px;
  font-size: 16px,

}

.pay-button:disabled {
  background: #ccc;
}
